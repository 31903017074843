import React, { useState, useEffect } from 'react';

import Navigation from '../components/Navigation/Navigation';

import '../styles/work.scss';

import { workObject, WorkType } from '../static/work'
import { setMetaData } from '../helpers/meta';

// @ts-ignore
import ExitIcon from '../components/Navigation/assets/exit-icon.svg';

const Work = () => {

  const [ selectedWork, setSelectedWork ] = useState<WorkType | null>(null);
  const [ isTransitioning, setTransition ] = useState<boolean>(false);

  useEffect(() => {
    if(isTransitioning) {
      setTimeout(() => {
        setSelectedWork(null);
      }, 400)
    }
  }, [isTransitioning])

  return (
    <>
      {setMetaData('Steven Duval Ruilova | Work')}
      <Navigation/>
      {selectedWork && (
        <div
          style={{ animationName: isTransitioning ? 'fadeOut' : 'fadeIn'}}
          className="workDetailsContainer"
        >
          <img
            onClick={() => setTransition(true)}
            src={ExitIcon}
          />
          <div className="workDetails">
            <h1>{selectedWork.client}</h1>
            <img src={selectedWork.images[1]}/>

            <div className="workDetailsInfo">
              <p className="title">
                <span>Role:</span> {selectedWork.role}
              </p>
              <p className="title">
                <span>Technologies:</span> {selectedWork.technologies.join(', ')}
              </p>
              {/* Allows handling of custom body element rendering */}
              <div className="body" dangerouslySetInnerHTML={{__html: selectedWork.body}}/>
            </div>
          </div>
        </div>
      )}
      <div className="workPageWrapper">
        <div className="workContainer">
          {workObject.map((object, index) => {
            return (
              <div
                key={object.client}
                onClick={() => {
                  setSelectedWork(object);
                  setTransition(false);
                }}
                /* Handles delay staggering between array objects */
                style={{ animationDelay: `${index * 0.15}s`}}
                className="work"
              >
                <img src={object.images[0]}/>
                <h3>{object.client}</h3>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default Work;
