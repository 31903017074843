export interface WorkType {
  client: string;
  images: Array<string>
  role: string;
  technologies: Array<string>
  body: string;
}

// @ts-ignore
import TH1 from '../images/work/the-hundreds-1.png';
// @ts-ignore
import TH2 from '../images/work/the-hundreds-2.png';

// @ts-ignore
import MS1 from '../images/work/onsite-1.png';
// @ts-ignore
import MS2 from '../images/work/onsite-2.png';

// @ts-ignore
import RMD1 from '../images/work/rmd-1.png';
// @ts-ignore
import RMD2 from '../images/work/rmd-2.png';

// @ts-ignore
import SIB1 from '../images/work/sib-1.png';
// @ts-ignore
import SIB2 from '../images/work/sib-2.png';

// @ts-ignore
import BB1 from '../images/work/bb-1.png';
// @ts-ignore
import BB2 from '../images/work/bb-2.gif';

// @ts-ignore
import LC1 from '../images/work/lc-1.png';
// @ts-ignore
import LC2 from '../images/work/lc-2.png';

// @ts-ignore
import ML1 from '../images/work/ml-1.png';
// @ts-ignore
import ML2 from '../images/work/ml-2.gif';

// @ts-ignore
import GR1 from '../images/work/gr-1.png';
// @ts-ignore
import GR2 from '../images/work/gr-2.png';



export const workObject:Array<WorkType> = [
  {
    client: 'Google Restor',
    images: [
      GR1,
      GR2
    ],
    role: 'Developer',
    technologies: ['React', 'Firebase'],
    body: `
      <p>
        Google partnered with the talented folks at CrowtherLabs Zürich to build a platform to "support and connect the global restoration movement".  
      </p>

      <p>
         Synthesizing the mountains of data, APIs and external illustration libraries to do so was no small task. I joined the project as a developer responsible for the implementation of various UI elements
         that managed communication between all three. Well thought out components played a large role in re-usability across the platform, while teamwork between the UI and data teams
         ensured clear and concise props and state management. Working with the talented folks at <a href="https://www.creativelab5.com" target="__blank" rel="noopener">Google Creative Labs</a> and Siberia,
         the final result was an informative, collaborative and illustrative tool.
      </p>

      <p>
        When working with world class teams separated by countries and oceans, communication is key. Key stakeholders drove design, design drove development, and all three providing constant feedback
        sharpened the final product into a well crafted tool. It's not very often that you get a chance to work with such creative folks at all three stages of product development, and the final product
        reflects the passion and devotion to craft of every single person that contributed.
      </p>      
    `
  },
  {
    client: 'MediaLink',
    images: [
      ML1,
      ML2
    ],
    role: 'Technology Director',
    technologies: ['React Gatsby', 'Prismic'],
    body: `
      <p>
        The ask was simple - design and build a world class website for a world class firm. The execution, on the other hand, required a complex dive into the interplay of design, development, animation, and content management.
      </p>

      <p>
        As technology director, I coordinated and led requirements, systems architecture, and development between dev/design/product teams.
        The biggest hurdle was finding a lightweight content management solution. Luckily, <a href="https://prismic.io" target="__blank" rel="noopener">Prismic</a>
        provided us the perfect use case with it's lightweight, headless implementation. At that point, it was a matter of configuring the proper models to achieve
        an easy-to-use upload process for MediaLink. The rest was handled by a modularized approach utilizing React Gatsby for it's speed and future
        maintenance considerations.
      </p>

      <p>
        As usual, the project was facilitated with constant communication between and within teams. As Technology Director, I made it a point to be present
        and active in all requirement and design meetings. Doing so allowed the dev team to voice questions and/or concerns, which played a large role
        in facilitating development against such a tight deadline. The final product was a <a href="https://medialink.com" target="__blank" rel="noopener">polished gem</a> 
        that I think perfectly encapsulates MediaLink as an entertainment industry market mover.
      </p>      
    `
  },
  {
    client: 'The Hundreds',
    images: [
      TH1,
      TH2
    ],
    role: 'Tech Lead, Main Developer',
    technologies: ['Shopify Liquid', 'Javascript'],
    body: `
      <p>
      <a href="https://thehundreds.com" target="__blank" rel="noopener">The Hundreds</a> is a streetwear brand based out of Los Angeles, California.
      </p>

      <p>
        I was brought on as lead developer for new feature consultation and implementation.
        Features ranged from dynamic price calculation to UI extensions for sales campaigns built around products & collections.
      </p>

      <p>
        Working with the liquid object layout is always a trip. Since my last foray into the world of Shopify, I was pleasantly surprised by
        their updated framework, particularly around theme customization and increased modularity of section and snippets.
      </p>
      
    `
  },
  {
    client: 'Edge Market Share',
    images: [
      MS1,
      MS2
    ],
    role: 'Tech Lead, Sole Developer',
    technologies: ['Vanilla Javascript (Chrome Extension)', 'Reactjs', 'AWS Amplify'],
    body: `
      <p>
        Ascential Edge is a digital marketing company focused on "combining actional insight, retail forecasting and advistory to help brands". 
      </p>

      <p>
        I was brought on as lead project developer to build out a <a href="https://www.ascentialedge.com/insights/ecommerce-blog/grow-market-share/market-share-onsite" target="__blank" rel="noopener">Google Chrome Extension</a> to embed their data services into Amazon product pages.
        This project required a unique approach, particularly with how to best build a stateful UI, data driven application without having to rely solely on vanilla
        Javascript.
      </p>

      <p>
        Enter 2 layer solutions - one scaffolding; one UI/state handling. The scaffolding layer itself handled all the chrome store metadata/configurations and logic necessary
        to alter and embed an iframe element into the DOM. From there, the iframe element hosted an external AWS Amplify URL that rendered a Reactjs application.
      </p>
      
      <p>
        The two layers participate in 2 way communication to authenticate data and events so as to give the overall application a seamless feel. From there, it was a straightfoward
        build using an external API to fetch the necessary data.
      </p>

      <p>
        The project itself was a case study on inter-disciplinary team communications. As requirements and available data were constant moving targets, I learned early on
        that communicating feedback & expectations were key to keeping the project steady on all sides. Ultimately, it was this this that kept the project on track
        and allowed us to deliver an exceptional product for clients.
      </p>
    `
  },{
    client: 'Little Cinema',
    images: [
      LC1,
      LC2
    ],
    role: 'Front End Developer',
    technologies: ['React TypeScript', 'AWS Amplify'],
    body: `
      <p>
        Little Cinema is a creative and digital studio specializing in immersive experiences and the reimagination of cinema. At the height of the first wave of COVID-19, media companies
        were scrambling to adapt to new restrictions, particularly around in-person premieres.
      </p>

      <p>
        I was brought on as a front end developer to assist in building version 1.0 of Little Cinema's virtual premiere platform. Aimed to address in-person restrictions by
        providing a virtual gathering for attendees to come together and watch/interact with new high profile premieres (TNT/HBO/Netflix/Amazon Studios), the platform was a team effort between software
        and media engineering. The premieres would be split it into two events - premiere and afterparty. Both would stream content and allow users to interact through chat rooms.
      </p>

      <p>
        Built in Reactjs, the project was a perfect opportunity to take advantage of components. As the UI of the platform was templated between the premiere and various afterparty events,
        the objective was take advantage of React's modularized approach to build data agnostic code which could be re-used from event to event. In the spirit of templating, we used TypeScript
        to enforce type checks and provide interfaces for developers moving forward.
      </p>

      <p>
        Ultimately, the project was <a href="https://www.vanityfair.com/hollywood/2020/05/hollywood-premieres-coronavirus" target="__blank" rel="noopener">considered a big success</a>. Our team worked on the first three
        premieres, which included TNT's SnowPiercer, HBO Max's Love Life and HBO Max's Legendary. The experience was yet another case study in the importance of communications between teams given the
        scope and work involved in such a complex ecosystem.
      </p>
    `
  },
  {
    client: 'Siberia 2.0',
    images: [
      SIB1,
      SIB2
    ],
    role: 'Front End Developer',
    technologies: ['React-Gatsby', 'Netlify'],
    body: `
      <p>
        <a href="https://siberia.io" target="__blank" rel="noopener">Siberia</a> is a product development firm shaping the future with the people who create it.
      </p>

      <p>
        I was brought on as one of a team of developers to build out the new Siberia 2.0 site. As always, it was a fine line to walk between deadlines, expectation and output.
      </p>

      <p>
        As a developer, it's always interesting to work with world class designers. Their expectation are lofty, and it's always a challenge to set that bar with constructive
        communication, feedback and expectation setting. By the end, it was a well polished piece of art that we were all proud of.
      </p>
      
    `
  },
  {
    client: 'BlackBox',
    images: [
      BB1,
      BB2
    ],
    role: 'Side Project, Sole Developer/Designer',
    technologies: ['Reactjs', 'Web3js', 'Solidity', 'Ganache', "InterPlanetary File System (IPFS)"],
    body: `
      <p>
        Since early 2015, I've been interested in the cryptocurrency space. <a href="https://github.com/sduval215/blackbox-solidity" target="__blank" rel="noopener">Blackbox</a> is a first attempt at web 3.0, focused around decentralized data storage/fetching.
      </p>

      <p>
        Built on the Ethereum blockchain, it's a first attempt to familiarize myself with decentralized computing smart contracts. Using Solidity, I started
        with the simple idea of uploading a file onto <strong>IPFS</strong> and associating that data on the blockchain with a users Ethereum address. The user could
        then view their previous submissions based only on their address and view their data, now available for all time (or until IPFS goes defunct).
      </p>

      <p>
        The tools that exist to facilitate blockchain development have come a long way. Ganache, for example, was an immense help in being able to 
        stand up a test blockchain locally for transaction purposes. Similarly, Web3js served the important role of being able to interact with the smart contract
        using Javascript on React.
      </p>

      <p>
        I haven't gotten around to putting it on a testnet, but that's only because other work calls. As inspiration rolls in, I'd like to continue building out 
        the platform and integrate zero knowledge proof for true anonymity. Until then, it will continue to be a learning tool for reference and further education
        into the decentralized computing space.
      </p>

      <strong>#buybitcoin</strong>
      
    `
  },
  {
    client: 'Rate My Death',
    images: [
      RMD1,
      RMD2
    ],
    role: 'Tech Lead, Sole Developer',
    technologies: ['Reactjs', 'Netlify'],
    body: `
      <p>
        <a href="https://www.ratemydeath.org" target="__blank" rel="noopener">Rate My Death</a> was a passion project at the height of the first wave of COVID-19.
      </p>

      <p>
        A friend had reached out and wanted to create an infographic site that illustrated the absurdity of the times. I jumped on as lead developer 
        and worked with a designer and content creator to bring it to life. Unfortunately, there weren't any concrete APIs that were able to give us
        up to date information, so we settled on a daily updated static JSON file.
      </p>

      <p>
        The site primarily leaned on user interaction and Twitter's embedded intent system to tweet the statistics on the users own account. The rest was an emphasized implementation
        on dynamic rendering based on key resolution breakpoints.
      </p>
    `
  }
]